/**
 * Use it to create simple modal controllers
 */
export function useModal() {
  const isOpen = ref(false);

  const open = () => {
    isOpen.value = true;
  };

  const close = () => {
    isOpen.value = false;
  };

  const toggle = () => {
    if (isOpen.value) close();
    else open();
  };

  return {
    isOpen: computed(() => isOpen.value),
    open,
    close,
    toggle,
  };
}

export const useSideMenuModal = createSharedComposable(useModal);

export function useModalBackground() {
  const openModalsCount = useState("open-modals-count", () => 0);

  const visible = computed(() => openModalsCount.value > 0);

  function show() {
    openModalsCount.value++;
  }

  function hide() {
    // delay by one tick to keep open if modal changes
    nextTick().then(() => openModalsCount.value--);
  }

  function toggle(visible: boolean) {
    if (visible) show();
    else hide();
  }

  return { visible, show, hide, toggle };
}
